export const getScriptContentFromHTMLString = (htmlString: string): string => {
  const parser = new DOMParser();
  const parsedHTML = parser.parseFromString(htmlString, "text/html");
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [scriptElement] = parsedHTML.getElementsByTagName("script");

  return scriptElement && scriptElement.innerText;
};

export const removeScriptTagFromHTMLString = (htmlString: string): string => {
  const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

  return htmlString.replace(SCRIPT_REGEX, "");
};
