import React, { useEffect } from "react";
import {
  getScriptContentFromHTMLString,
  removeScriptTagFromHTMLString,
} from "./utils";
import { IDynamicBlockComponent } from "../../dynamicPageBuilder/IDynamicBlockComponent";

type HtmlBlockProps = {
  background: string;
  picture: {
    pictureUrl: string;
  };
  html: string;
  css: string;
};

const HtmlBlock: React.FunctionComponent<IDynamicBlockComponent> = ({
  blockData,
}): React.ReactElement => {
  const { background, html, picture, css } = blockData as HtmlBlockProps;
  const backgroundImage =
    picture && `url('${process.env.NEXT_PUBLIC_IMG_URL}${picture.pictureUrl}')`;

  const htmlContentWithoutScripts = html
    ? removeScriptTagFromHTMLString(html)
    : html;

  const htmlContent = css
    ? `<style>${css}</style> ${htmlContentWithoutScripts}`
    : htmlContentWithoutScripts;

  useEffect(() => {
    const scriptContent = getScriptContentFromHTMLString(html);

    if (scriptContent) {
      const script = document.createElement("script");

      script.async = true;
      script.type = "text/javascript";
      script.innerHTML = scriptContent;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <div
      style={{ background, backgroundImage }}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

export default HtmlBlock;
